import { rem } from 'polished';
import styled from 'styled-components';

const ProductItemActionsContainer = styled.div`
    bottom: ${rem(8)};
    height: ${rem(40)};
    position: absolute;
    right: ${rem(8)};
    z-index: 1;
`;

export default ProductItemActionsContainer;

import { useMutation } from '@apollo/client';
import { CREATE_PRODUCT, DELETE_PRODUCT } from './actionTypes';

const useCreateProductUser = (onCompletedCreateProduct = null) => {
    const [
        createProduct,
        { loading: isLoadingCreateProduct, error: isErrorCreateProduct },
    ] = useMutation(CREATE_PRODUCT, {
        notifyOnNetworkStatusChange: true,
        onCompleted: onCompletedCreateProduct,
    });

    const [
        deleteProduct,
        { loading: isLoadingDeleteProduct, error: isErrorDeleteProduct },
    ] = useMutation(DELETE_PRODUCT, {
        notifyOnNetworkStatusChange: true,
        onCompleted: onCompletedCreateProduct,
    });

    return {
        createProduct,
        isLoadingCreateProduct,
        isErrorCreateProduct,
        deleteProduct,
        isLoadingDeleteProduct,
        isErrorDeleteProduct,
    };
};

export default useCreateProductUser;

import { useEffect, useContext } from 'react';
import { isEmpty } from 'lodash';
import { useApolloClient } from '@apollo/client';
import { useLocation } from '@reach/router';
import { GET_PRODUCTS } from './actionTypes';
import useCreateProductUser from './useCreateProductUser';
import BrandContext from '../components/context/BrandContext';

const useAllProductUserCache = () => {
    const client = useApolloClient();
    const { createProduct, deleteProduct } = useCreateProductUser();
    const { allProductsUserFromClient, setAllProductsUserFromClient, userFromClient } = useContext(
        BrandContext,
    );
    const { pathname } = useLocation();

    useEffect(() => {
        const run = async () => {
            const { data: queryData } = await client.query({
                query: GET_PRODUCTS,
            });

            setAllProductsUserFromClient(queryData);
        };

        if (userFromClient) {
            if (!allProductsUserFromClient) {
                run();
            } else {
                const queryData = client.cache.readQuery({
                    query: GET_PRODUCTS,
                });

                if (queryData) {
                    setAllProductsUserFromClient(
                        client.cache.readQuery({
                            query: GET_PRODUCTS,
                        }),
                    );
                }
            }
        }
    }, [userFromClient]);

    useEffect(() => {
        if (allProductsUserFromClient && isEmpty(client.cache.data.data)) {
            client.cache.writeQuery({
                query: GET_PRODUCTS,
                data: {
                    allProducts: {
                        __typename: 'ProductPage',
                        data: [...allProductsUserFromClient.allProducts.data],
                    },
                },
            });
        }

        if (!isEmpty(client.cache.data.data)) {
            client.cache.readQuery({
                query: GET_PRODUCTS,
            });
        }
    }, [allProductsUserFromClient]);

    return {
        allProductsUserFromClient,
        setAllProductsUserFromClient,
        createProduct,
        deleteProduct,
        user: userFromClient,
        pathname,
        client,
    };
};

export default useAllProductUserCache;

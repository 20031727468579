import styled from 'styled-components';
import { rem } from 'polished';

const ProductItemHeader = styled.div`
    border-radius: ${({ theme }) => rem(theme.base.borderRadius.md)};
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-end;
    overflow: hidden;
`;

export default ProductItemHeader;

import React from 'react';
import { Loader } from '@luigiclaudio/ga-baseline-ui/helpers';
import ProductItemActionsLoaderContainer from './ProductItemActionsLoaderContainer';

const ProductItemActionsLoader = () => {
    return (
        <ProductItemActionsLoaderContainer>
            <Loader size={20} />
        </ProductItemActionsLoaderContainer>
    );
};

export default ProductItemActionsLoader;

import styled from 'styled-components';
import { rem, transparentize } from 'polished';

const ProductItemDetails = styled.div`
    background-color: ${({ theme, themeMode }) =>
        transparentize(themeMode === 'darkMode' ? 0.5 : 0.2, theme.site.stage.backgroundColor)};
    border-radius: ${({ theme }) => rem(theme.base.borderRadius.md)};
    display: inline-flex;
    padding: ${rem(2)} ${rem(8)};
`;

export default ProductItemDetails;

import styled from 'styled-components';
import { rem } from 'polished';

const ProductItemActionsLoaderContainer = styled.div`
    align-items: center;
    display: flex;
    height: 100%;
    padding: 0 ${rem(8)};
`;

export default ProductItemActionsLoaderContainer;

import styled from 'styled-components';
import { H5 } from '@luigiclaudio/ga-baseline-ui/typography';

const ProductTitle = styled(H5).attrs(() => ({
    color: 'dark',
}))`
    margin-bottom: 0;
    text-transform: capitalize;
`;

export default ProductTitle;

import styled from 'styled-components';
import { h6Styles } from '@luigiclaudio/ga-baseline-ui/typography';

const ProductItemPrice = styled.span.attrs(() => ({
    colorValue: 'dark',
}))`
    ${h6Styles}
`;

export default ProductItemPrice;

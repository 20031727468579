import React from 'react';
import PropTypes from 'prop-types';
import ProductItemActionsContainer from './ProductItemActionsContainer';
import ProductItemActionButton from './ProductItemActionButton';
import useCreateProductUser from '../../../services/useCreateProductUser';
import productPropTypes from '../../../prop-types/productPropTypes';
import ProductItemActionsLoader from './ProductItemActionsLoader';

const ProductItemActions = ({ userProduct, onHotClick }) => {
    const { id, url } = userProduct;

    const { isLoadingCreateProduct, isLoadingDeleteProduct } = useCreateProductUser();

    const renderAction =
        isLoadingCreateProduct || isLoadingDeleteProduct ? (
            <ProductItemActionsLoader />
        ) : (
            <ProductItemActionButton icon="heart" onClick={() => onHotClick(url, id)} id={id} />
        );

    return <ProductItemActionsContainer>{renderAction}</ProductItemActionsContainer>;
};

ProductItemActions.propTypes = {
    userProduct: productPropTypes.userProduct,
    onHotClick: PropTypes.func,
};

ProductItemActions.defaultProps = {
    userProduct: undefined,
    onHotClick: () => {},
};

export default ProductItemActions;

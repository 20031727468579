import styled from 'styled-components';

const ProductItemImageContainer = styled.div`
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: ${({ theme }) => theme.site.productItem.image.width};
    z-index: 1;
`;

export default ProductItemImageContainer;

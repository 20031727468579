import { rem, transparentize } from 'polished';
import { css } from 'styled-components';

const productItemOverlayStyles = () => css`
    background-image: linear-gradient(
        to top,
        ${({ theme }) => transparentize(0.025, theme.site.stage.backgroundColor)} 0%,
        ${({ theme }) => transparentize(1, theme.site.stage.backgroundColor)} 100%
    );
    bottom: 0;
    padding: ${rem(80)} ${rem(16)} ${rem(16)} ${rem(16)};
    position: absolute;
    text-shadow: 0 1px 2px ${({ theme }) => transparentize(0.5, theme.site.stage.backgroundColor)};
    width: 100%;
    z-index: 1;
`;

export default productItemOverlayStyles;

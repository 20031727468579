import styled from 'styled-components';
import { smallStyles } from '@luigiclaudio/ga-baseline-ui/typography';

const ProductItemPriceBeforeSale = styled.span.attrs(() => ({
    colorValue: 'muted',
}))`
    ${smallStyles}
    margin: 0;
`;

export default ProductItemPriceBeforeSale;

import styled from 'styled-components';
import { pulseKeyframes } from '@luigiclaudio/ga-baseline-ui/helpers';

const ProductItemImagePlaceholder = styled.span`
    animation: ${pulseKeyframes} 1s infinite ease-in-out;
    background-color: ${({ theme }) => theme.base.grays.grayLighter};
    display: block;
    height: 100%;
    width: ${({ theme }) => theme.site.productItem.image.width};
    z-index: 0;
`;

export default ProductItemImagePlaceholder;

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@luigiclaudio/ga-baseline-ui/button';
import ProductItemActionContainer from './ProductItemActionContainer';

const ProductItemActionButton = ({ icon, id, onClick }) => {
    return (
        <ProductItemActionContainer>
            <Button
                btnType={id ? 'default' : 'link'}
                icon={icon}
                onClick={onClick}
                type="button"
                skin={id ? 'danger' : 'primary'}
                iconSize={id ? 16 : 24}
            />
        </ProductItemActionContainer>
    );
};

ProductItemActionButton.propTypes = {
    id: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    icon: PropTypes.string.isRequired,
};

ProductItemActionButton.defaultProps = {
    id: undefined,
};

export default ProductItemActionButton;

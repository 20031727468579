import { helpersUtils } from '@luigiclaudio/ga-baseline-ui/helpers';
import { navigate } from 'gatsby';
import { GET_PRODUCTS } from '../services/actionTypes';

const handleOnHotClick = async ({
    currentUrl,
    currentId,
    currentProduct,
    setAllProductsUserFromClient,
    allProductsUserFromClient,
    client,
    deleteProduct,
    createProduct,
    user,
    pathname,
}) => {
    if (user) {
        client.cache.updateQuery({ query: GET_PRODUCTS }, (currentData) => {
            const currentItem = {
                __typename: 'Product',
                ...currentProduct,
                id: helpersUtils.generateUniqueId(),
            };

            let mutationData;

            if (!currentId) {
                mutationData = [currentItem];
                if (!currentData) {
                    mutationData.push(...allProductsUserFromClient.allProducts.data);
                } else {
                    mutationData.push(...currentData.allProducts.data);
                }
                setAllProductsUserFromClient({
                    allProducts: {
                        __typename: 'ProductPage',
                        data: mutationData,
                    },
                });
            }

            if (currentId) {
                if (!currentData) {
                    mutationData = allProductsUserFromClient.allProducts.data.filter(
                        (item) => item.url !== currentUrl,
                    );
                } else {
                    mutationData = currentData.allProducts.data.filter(
                        (item) => item.url !== currentUrl,
                    );
                }
                setAllProductsUserFromClient({
                    allProducts: {
                        __typename: 'ProductPage',
                        data: mutationData,
                    },
                });
            }

            return {
                allProducts: {
                    __typename: 'ProductPage',
                    data: mutationData,
                },
            };
        });

        if (currentId) {
            await deleteProduct({
                variables: { url: currentUrl },
            });
        }

        if (!currentId) {
            await createProduct({
                variables: currentProduct,
            });
        }
    }

    if (!user) {
        navigate('/signIn', {
            state: {
                pathname,
                product: currentProduct,
            },
        });
    }
};

export default handleOnHotClick;

import { useEffect, useState } from 'react';
import { useLocation } from '@reach/router';
import handleOnHotClick from '../utils/handleOnHotClick';
import useAllProductUserCache from './useAllProductUserCache';

const useCreateProductInLocation = () => {
    const { state } = useLocation();
    const [productInLocation, setProductInLocation] = useState();

    const {
        allProductsUserFromClient,
        setAllProductsUserFromClient,
        createProduct,
        deleteProduct,
        user,
        pathname,
        client,
    } = useAllProductUserCache();

    useEffect(() => {
        if (state?.product) {
            setProductInLocation(state.product);
        }
    }, []);

    useEffect(() => {
        if (user && productInLocation && allProductsUserFromClient) {
            const { url } = productInLocation;

            const isHotProduct = allProductsUserFromClient?.allProducts?.data?.find(
                (productUser) => {
                    return productUser.url === url;
                },
            );

            if (!isHotProduct) {
                const handleHotProductInLocation = async () => {
                    handleOnHotClick({
                        currentUrl: url,
                        currentId: undefined,
                        currentProduct: productInLocation,
                        setAllProductsUserFromClient,
                        allProductsUserFromClient,
                        client,
                        createProduct,
                        deleteProduct,
                        user,
                        pathname,
                    });
                };
                handleHotProductInLocation();
            }
        }
    }, [user, productInLocation, allProductsUserFromClient]);
};

export default useCreateProductInLocation;

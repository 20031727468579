import styled from 'styled-components';
import { rem, tint } from 'polished';
import { GatsbyA } from '@luigiclaudio/ga-baseline-ui/typography';

const ProductItemLink = styled(GatsbyA)`
    backface-visibility: hidden;
    background-color: ${({ theme }) => tint(0.025, theme.site.stage.backgroundColor)};
    border-radius: ${({ theme }) => rem(theme.base.borderRadius.md)};
    box-shadow: ${({ theme }) => theme.base.boxShadow.elevationDefault};
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
    -webkit-perspective: 1000;
`;

export default ProductItemLink;

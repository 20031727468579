import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber, FormattedMessage } from 'react-intl';
import { ProgressiveBg } from '@luigiclaudio/ga-baseline-ui/helpers';
import ProductItemContainer from './ProductItemContainer';
import ProductItemLink from './ProductItemLink';
import ProductItemImage from './ProductItemImage';
import ProductItemPriceContainer from './ProductItemPriceContainer';
import ProductItemDetails from './ProductItemDetails';
import ProductItemHeader from './ProductItemHeader';
import ProductItemImageContainer from './ProductItemImageContainer';
import ProductItemImagePlaceholder from './ProductItemImagePlaceholder';
import ProductItemDiscountedPriceLabel from './ProductItemDiscountedPriceLabel';
import brandUtils from '../../../utils/brandUtils';
import BrandContext from '../../context/BrandContext';
import ProdcutItemSmall from './ProdcutItemSmall';
import ProductTitle from './ProductTitle';
import ProductItemPriceBeforeSale from './ProductItemPriceBeforeSale';
import ProductItemPrice from './ProductItemPrice';
import ProductItemHeaderContainer from './ProductItemHeaderContainer';
import ProductItemActions from '../ProductItemActions';

const ProductItem = ({
    rowIndex,
    brand,
    url,
    image,
    title,
    price,
    priceBeforeSale,
    currentLocalPrice,
    intlKeywords,
    retailer,
    group,
    currency,
    itemWidth,
    itemHeight,
    themeMode,
    isHotId,
    onHotClick,
}) => {
    const {
        brandDetailsOnStage,
        productOnStage,
        setProductOnStage,
        setProductListCurrentRowIndex,
    } = useContext(BrandContext);
    const { brand: brandOnStage } = brandDetailsOnStage;

    const priceFormatOptions = brandUtils.priceFormatOptions(currency);

    const renderPrice = !price ? (
        <ProductItemPrice>
            <FormattedNumber value={priceBeforeSale} {...priceFormatOptions} />
        </ProductItemPrice>
    ) : (
        <>
            <ProductItemPrice>
                <FormattedNumber value={price} {...priceFormatOptions} />
            </ProductItemPrice>
            <ProductItemPriceBeforeSale>
                <ProductItemDiscountedPriceLabel>
                    <FormattedNumber value={priceBeforeSale} {...priceFormatOptions} />
                </ProductItemDiscountedPriceLabel>
            </ProductItemPriceBeforeSale>
        </>
    );

    const imageBoxShadow = `inset 0 0 24px 0px rgb(0 0 0 / ${
        themeMode === 'lightMode' ? '8%' : '32%'
    })`;

    const userProduct = {
        id: isHotId,
        brand,
        title,
        url,
        image,
        retailer,
        group,
        price,
        priceBeforeSale,
        currentLocalPrice,
        intlKeywords,
        currency,
    };

    return (
        <ProductItemContainer itemWidth={itemWidth} itemHeight={itemHeight}>
            <ProductItemActions userProduct={userProduct} onHotClick={onHotClick} />
            <ProductItemLink
                to={`/showProduct?brand=${brandUtils.getBrandSlug(brand)}&url=${encodeURI(url)}`}
                state={{ isCategory: brand !== brandOnStage }}
                onClick={() => {
                    setProductListCurrentRowIndex(rowIndex);

                    if (productOnStage?.url !== url) {
                        setProductOnStage(undefined);
                    }
                }}
            >
                <ProductItemHeader>
                    <ProductItemImage>
                        <ProductItemImageContainer>
                            <ProgressiveBg image={image} style={{ boxShadow: imageBoxShadow }} />
                        </ProductItemImageContainer>
                        <ProductItemImagePlaceholder />
                    </ProductItemImage>
                    <ProductItemHeaderContainer>
                        {brand !== brandOnStage && (
                            <ProductItemDetails themeMode={themeMode}>
                                <FormattedMessage
                                    id="productItem.header.intro"
                                    values={{
                                        brand: (
                                            <ProdcutItemSmall colorValue="dark">
                                                {brand}
                                            </ProdcutItemSmall>
                                        ),
                                    }}
                                />
                            </ProductItemDetails>
                        )}
                        <ProductTitle>{title}</ProductTitle>
                        <ProductItemPriceContainer>{renderPrice}</ProductItemPriceContainer>
                        <ProdcutItemSmall colorValue="muted">
                            <FormattedMessage id={`retailer.${retailer}`} />
                        </ProdcutItemSmall>
                    </ProductItemHeaderContainer>
                </ProductItemHeader>
            </ProductItemLink>
        </ProductItemContainer>
    );
};

ProductItem.propTypes = {
    rowIndex: PropTypes.number,
    brand: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    image: PropTypes.string,
    title: PropTypes.string.isRequired,
    price: PropTypes.number,
    intlKeywords: PropTypes.arrayOf(PropTypes.string),
    priceBeforeSale: PropTypes.number,
    currentLocalPrice: PropTypes.number,
    retailer: PropTypes.string.isRequired,
    group: PropTypes.string.isRequired,
    currency: PropTypes.string,
    itemWidth: PropTypes.number.isRequired,
    itemHeight: PropTypes.number,
    themeMode: PropTypes.string.isRequired,
    isHotId: PropTypes.string,
    onHotClick: PropTypes.func,
};

ProductItem.defaultProps = {
    rowIndex: undefined,
    image: undefined,
    price: undefined,
    priceBeforeSale: undefined,
    currentLocalPrice: undefined,
    isHotId: undefined,
    currency: undefined,
    intlKeywords: undefined,
    onHotClick: undefined,
    itemHeight: undefined,
};

export default ProductItem;

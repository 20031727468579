import PropTypes from 'prop-types';
import productGroupData from '../data/productGroupData';

const productGroup = PropTypes.oneOf(productGroupData);
const productGroups = PropTypes.arrayOf(PropTypes.string);

const product = PropTypes.shape({
    title: PropTypes.string,
    price: PropTypes.number,
    priceBeforeSale: PropTypes.number,
    currentPrice: PropTypes.number,
    currentLocalPrice: PropTypes.number,
    image: PropTypes.string,
    group: productGroup,
    brand: PropTypes.string,
    url: PropTypes.string,
    retailer: PropTypes.string,
    country: PropTypes.string,
    intlKeywords: PropTypes.arrayOf(PropTypes.string),
    currency: PropTypes.string,
});

const products = PropTypes.arrayOf(product);

const userProduct = PropTypes.shape({
    id: PropTypes.string,
    brand: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
    image: PropTypes.string,
    retailer: PropTypes.string,
    price: PropTypes.number,
    priceBeforeSale: PropTypes.number,
    currentPrice: PropTypes.number,
    currentLocalPrice: PropTypes.number,
    intlKeywords: PropTypes.arrayOf(PropTypes.string),
    currency: PropTypes.string,
});

const userProducts = PropTypes.arrayOf(userProduct);

export default { productGroup, productGroups, product, products, userProduct, userProducts };

import { rem } from 'polished';
import styled from 'styled-components';

const ProductItemActionContainer = styled.div`
    display: flex;
    height: ${rem(40)};
    justify-content: center;
    width: ${rem(48)};
`;

export default ProductItemActionContainer;
